import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function ArsisReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Arsis Developers</h4>
            
            <p className="mb-0">
                <p className="AboutExpoPara">Arsis Developers, A Bengaluru-based company established in 2014, is one of the leading names redefining the standards of excellence in the residential scenario. Since inception, Arsis Developers has accomplished several remarkable residential projects. Each one with outstanding & unique design, imposing stature, and enduring robustness.</p>

            </p>

            {isShowMore && (
                
                 <p className="mb-0">
                <p className="AboutExpoPara">Arsis Developers has always been uncompromising in quality and unfailing in timely delivery. The reason why we have been able to win the trust of a noteworthy clientele and build on our track record. Architectural excellence, impeccable engineering skills, and ground-breaking innovations have all become synonymous with the brand Arsis.
</p>
                <p className="AboutExpoPara">Our most recent launch is Arsis Green Hills Bengaluru, which comprises 2, 2.5 & 3 BHK luxury apartments in K R Puram. Affordable pricing even for premium homes being one of our hallmarks, you will pleasantly find Arsis Green Hills totally within your reach.
</p>

<h6>Vision</h6>
<p className="AboutExpoPara">To become the leader in the Bengaluru Real Estate industry</p>
<p className="AboutExpoPara">To be widely known as a reliable, service-oriented, and preferred developer.</p>
<p className="AboutExpoPara">To become a multi-state player in India</p>

<h6>Mission</h6>

<p className="AboutExpoPara">Ethical business dealings</p>
<p className="AboutExpoPara">Strict adherence to legalities & statutory requirements</p>
<p className="AboutExpoPara">Ensuring superior quality, fair pricing, and timely delivery
</p>
<p className="AboutExpoPara">Dedicated customer service</p>

            </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default ArsisReadMore;